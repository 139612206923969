import { ServiceData } from "../../components/ServiceCard";

const services: ServiceData[] = [
    {
        title: "Montaż, serwis reklam",
        description:
            "Najchętniej montujemy reklamy własnej produkcji ale podejmujemy się również prac montażowych i serwisów reklam powierzonych przez Klienta.",
        imageCode: "1",
    },
    {
        title: "Wyklejanie tablic billboardowych",
        description:
            "Wyklejamy tablice własnymi wydrukami oraz powierzonymi przez klienta. Obsługujemy zarówno pojedyncze akcje jak i stałe zlecenia klejenia tablic sieci handlowych.",
        imageCode: "2",
    },
];

const getAssemblyData = () => services;

export default getAssemblyData;
