import * as React from "react";
import Layout from "../components/Layout";
import ServicesPanel from "../components/ServicesPanel";
import useColor from "../context/ColorContext";
import getAssemblyData from "../utils/functions/getAssamblyData";

const AssemblyPage = () => {
    const { setColor } = useColor();
    const services = getAssemblyData();

    setColor?.("yellow");
    return (
        <>
            <Layout>
                <ServicesPanel
                    description="Montaż i serwis wszystkich oferowanych produktów a także powierzonych przez Klienta."
                    heading={"Montaż reklam"}
                    services={services}
                    category={"montaz"}
                />
            </Layout>
        </>
    );
};

export default AssemblyPage;
